import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Clientlogos(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const client_logo_data = props.client_logo_data ?? [];

    const client_logo_list = client_logo_data.map((logo) =>
        <div className="col-md-3 scroll-animation" data-aos='fade-right' key={logo.uid}>
            <img src={'https://ankyx.b-cdn.net/ag/assets/images/client-logo/'+logo.image_link} className='client-logo' alt="Client"/>
        </div>
    )
    
  return (
    <section className="contact-area page-section scroll-content" id="clientlogos">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="clients-logos">
                    <h4 className="scroll-animation" data-aos='fade-up'>work with 60+ brands worldwide</h4>
                    <div className="row align-items-center">
                        {client_logo_list}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}


