import React, { useState, useEffect } from 'react'
import Pageloader from '../Global-content/Pageloader'
import Rsidemenu from '../Global-content/Rsidemenu'
import Scrollnav from '../Global-content/Scrollnav'
import Lsb from '../Global-content/Lsb'
import Settings from '../Global-content/Settings'
import {Helmet} from 'react-helmet'
import {useParams} from 'react-router-dom';
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function BlogDetails() {

    const {cat_slug, subcat_slug, slug} = useParams();

    const [detail_data, setData]=useState({})
    const [blog_data, setBlog]=useState({})
    // const [currentVideo, setVideo]=useState('')
    const [seo_data, setSeo]=useState([])
    const [contact_data, setContact]=useState([])

    useEffect(()=>{
        AOS.init({duration:1000})
        send();
        send_blogs();
    }, [])
    
    const send = async () => {
        await fetch('https://api.codyx.me/api/website/details')
        // await fetch('http://localhost/api.codyx.me/api/website/details')
        .then(response => response.json())
        .then(json => { 
            setData(json.data);
            setSeo(json['data'].seo);
            setContact(json['data'].contact);
        })
        .catch(error => console.error(error));
    }

    const send_blogs = async () => {
      await fetch('https://api.codyx.me/api/website/blog_details', {
    //   await fetch('http://localhost/api.codyx.me/api/website/blog_details', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ cat_slug, subcat_slug, slug })
        })
        .then(response => response.json())
        .then(json => {
            console.log(json.data[0]);
            setBlog(json.data[0]);

            if(document.querySelector(".page-loader"))
                document.querySelector(".page-loader").remove();
        })
        .catch(error => console.error(error));
    }

    var seo_list = [];
    seo_data.map((seo) => {
        seo_list[seo.sub_group] = seo;
    });

    var contact_list = [];
    contact_data.map((contact) => {
        contact_list[contact.sub_group] = contact;
    });

  return (
    <div>
        <Helmet>
            
            <title>{blog_data.title}</title>

            <link rel="canonical" href={window.location.href} />

            <meta name="description" content={blog_data.description} />
            <meta name="keywords" content={seo_list['seo_keyword']?.remark} />
            <meta name="author" content="Ankush Gautam Freelance PHP Developer" />

            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />

            <meta property="og:title" content={blog_data.title} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={blog_data.title} />
            <meta property="og:description" content={blog_data.description} />
            <meta property="og:image" content=".https://ankyx.b-cdn.net/ag/assets/images/ankush-gautam.PNG" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={blog_data.title} />
            <meta name="twitter:description" content={blog_data.description} />

            <meta name="copyright" content={contact_list['self_name']?.title} />
            <meta name="reply-to" content={contact_list['self_email']?.title} />

        </Helmet>

        {/* <Video video={currentVideo}/> */}
        <Pageloader/>
        {/* Settings icon */}
        <Settings />

        {/* Left Side Bar and it will get executed on large devices*/}
        <Lsb website_data={detail_data} />

        {/*when user interacts with the hamburger menu it display the inner-components*/}
        <Rsidemenu/>

        {/*fixed on large screens easy to navigate through section og main components*/}
        <Scrollnav/>

        {/* heroSection, about, testimoni  */}
    
        <main className="drake-main">
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <section className="blog-area page-section scroll-to-page" id="blog">
                        <div className="custom-container">
                            <div className="blog-content content-width" key="{blog_data.uid}">
                                <div className="section-header">
                                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                                        Blogs 
                                         &nbsp; <i className="las la-angle-right"></i>{blog_data.mbc_title}
                                         &nbsp; <i className="las la-angle-right"></i>{blog_data.mbs_title}
                                    </h4>
                                    <h1 className="scroll-animation" data-aos='fade-up'>{blog_data.title}</h1>
                                </div>

                                <div className="blog-table-items">
                                    <div className="row">
                                        <div className="col-md-12 scroll-animation mb-4" data-aos='fade-up'>
                                            <div className="blog-table">
                                                <div className="blog-table-header">
                                                    <div className="top d-flex justify-content-between align-items-start">
                                                        <h5>{blog_data.mbs_title}</h5>
                                                        <p className="text-right">{blog_data.crdt_dt_format}</p>
                                                    </div>
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html: blog_data.blog?.replace(/(<? *script)/gi, 'illegalscript')}} ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    </div>
  )
}
