import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio(props) {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        Lightbox.option({
          resizeDuration: 200,
          fadeDuration: 600,
          imageFadeDuration: 600,
          wrapAround: true
        });
      }, []);

    const portfolio_data = props.portfolio_data ?? [];

    const createTags = (subtitle) => {
        var arr = [];
        var subtitle_arr = subtitle.split(',');
        for (let i = 0; i < subtitle_arr.length; i++) {
            arr.push(<li key={'li_'+i}>
                <a href="#">{subtitle_arr[i]}</a>
            </li>);
        }
        return <ul className="portfolio-categories">{arr}</ul>
    }

    const portfolio_list = portfolio_data.map((portfolio) =>
        <div className={'col-md-'+(portfolio.sub_group == 'website' ? '12' : '6')+' scroll-animation'} data-aos='fade-up' key={portfolio.uid}>
            <div className="portfolio-item portfolio-full">
                <div className={(portfolio.sub_group == 'website' ? '' : 'app') + ' portfolio-item-inner'}>
                    <a href={'https://ankyx.b-cdn.net/ag/assets/images/portfolio/'+portfolio.image_link} data-lightbox="example-1">
                        <img src={'https://ankyx.b-cdn.net/ag/assets/images/portfolio/'+portfolio.image_link} alt="Portfolio"/>
                    </a>
                    {createTags(portfolio.subtitle)}
                </div>
                <h2><a href="https://www.Bureau - Architecture Studio Website.com">{portfolio.title}</a></h2>
            </div>
        </div>
    )

  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> portfolio
                    </h4>
                    <h1 className="scroll-animation">Featured <span>Projects</span></h1>
                </div>

                <div className="row portfolio-items">
                    {portfolio_list}
                </div>
            </div>
        </div>
    </section>
  )
}
