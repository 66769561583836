import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Herosec(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
    
    const main_data = props.main_data ?? [];

    var main_list = [];

    main_data.map((main) => {
        main_list[main.sub_group] = main;
    });
    
    let start_year = main_list['years_of_experience']?.date_range;
    let total_years = (new Date().getFullYear() - start_year);
    let total_days = total_years * 365;
    
    let project_ratio    = 1  / 70;
    let clients_ratio    = 1  / 90;

    let project  = parseInt(total_days * project_ratio) ?? 0;
    let clients  = parseInt(total_days * clients_ratio) ?? 0;

  return (
    <section className="hero-section page-section scroll-to-page" id="home">
        <div className="custom-container">
            <div className="hero-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-animation="fade_from_bottom" data-aos='fade-up'>
                        <i className="las la-home"></i> Introduction
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Hola! <br />I'm <span>Ankush Gautam</span></h1>
                </div>
                <h4 className="scroll-animation" data-aos='fade-up'>I am a Mumbai-based Freelance Website, Android & iOS Mobile Developer providing Expert Consultation and Tailored Solutions.</h4>
                <a href="#portfolio" 
                    className="go-to-project-btn scroll-to scroll-animation" 
                     data-aos='fade-up'>
                    <img src="https://ankyx.b-cdn.net/ag/assets/images/round-text.png" alt="Rounded Text"/>
                    <i className="las la-arrow-down"></i>
                </a>    
                <div className="facts d-flex">
                    <div className="bottom scroll-animation" data-aos='fade-up'>
                        <h1>{total_years}+</h1>
                        <p>Years of <br/>Experience</p>
                    </div>
                    <div className="bottom scroll-animation" data-aos='fade-up'>
                        <h1>{clients}+</h1>
                        <p>Total <br/>Clients</p>
                    </div>
                    <div className="bottom scroll-animation" data-aos='fade-up'>
                        <h1>{project}+</h1>
                        <p>projects <br/>completed in <span>{main_list['no_of_countries']?.title} countries</span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
