import '.https://ankyx.b-cdn.net/ag/assets/css/bootstrap.min.css'
import './css/App.css'
import './css/responsive.css'
import Global from './components/Global'
import { BrowserRouter, HashRouter, Router, Routes, Route, Switch } from "react-router-dom"
import BlogDetails from './components/Main-Components/BlogsDetails'

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="blogs/:cat_slug/:subcat_slug/:slug" element={<BlogDetails />} />
        <Route path="/" exact element={<Global />} />
        <Route path="*" exact element={<Global />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );

  // <Global/>
}

export default App;
